<template>
	<div class="row small pointer">
		<div class="col-12 mt-4">
			<div class="card-img-container">
				<img :src="item.image?.downloadURL" class="card-img" alt="...">
			</div>
		</div>
		<div class="col-12">
			<div class="p-2">
				<div class="text-danger small" style="line-height: 1.2em;" v-if="item.flagged">
					This item and/or the user who posted this item has been flagged by other users.
				</div>
				<div v-else>
					<div >
						<div class="item-caption rounded-pill text-white badge mb-2" :class="badgeColor(item.post_type)">
							{{item.post_type}}
						</div>
						<p class="excerpt">
							{{item.details}}
						</p>
						<div class="item-caption small text-secondary">
							&bull; {{item.category}} &bull; {{item.country}}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "MarketplaceContent",
	props:{
		item: {
			type: Object,
			required: true,
		}
	},
	methods:{
		badgeColor(post_type){
			switch(post_type){
				case "selling":
					return "bg-info";
				case "buying":
					return "bg-warning";
				default:
					return "bg-dark";
			}
		},
	}
}
</script>
<style scoped>
@media only screen and (max-width: 600px) {
	.excerpt{
		font-size: 1.2rem;
	}
}
</style>