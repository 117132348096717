<template>
	<div class="row">
		<div class="col-lg-5 mb-3 px-lg-4">
			<img :src="activeItem.image?.downloadURL" class="card-img sticky-top" alt="...">
		</div>
		<div class="col-lg-7 mb-5" v-if="activeItem?.title">
			<div class="alert alert-danger my-3" v-if="activeItem.flagged">
				<i class="bi bi-flag"></i> This post (and user account) has been flagged by another user. You are strongly encouraged to ignore this post.
			</div>
			<div class="item-caption text-secondary mb-3" style="text-transform: lowercase;">{{timeAgo(activeItem.created_at)}}</div>
			<p class="h2 mb-4">{{ activeItem.title }}</p>
			<div class="my-2 mb-3 row">
				<AvatarPosts :author="activeItem.author" />
			</div>
			<div class="my-2 mb-4" v-if="!activeItem.flagged">
				<ItemActionBar :activeItem="activeItem" :dbCollection="collections.MARKETPLACE" :key="activeItem.id" />
			</div>
			<p class="my-3" v-show="activeItem.post_type=='selling'">
				<span class="h5 fw-bold">Price:</span><br/>
				<span class="h2">
					{{ activeItem.price ? formatCurrency(activeItem.price) : '' }}
				</span>
				<br/>
				<span class="badge bg-dark rounded-pill me-2" v-for="method in activeItem.payment_methods" :key="method">
					{{payment_methods.find((pm) => pm.id === method).name}}
				</span>
			</p>
			<div class="mt-4">
				<span class="h5 fw-bold">Description:</span>
				<div class="my-3" style="text-transform: capitalize">
					&bull; <span class="small">Type:</span> {{ activeItem.post_type }} <br/> 
					&bull; <span class="small">Category:</span> {{ activeItem.category }} <br/>
					&bull; <span class="small">Country:</span> {{ activeItem.country }}
				</div>
			</div>
			<div class="mb-3 ">
				{{activeItem.details}}
			</div>
			<SendMessageBox class="mt-4 mb-5" v-if="activeItem.author.id !== userProfile.id" 
			:flagged="activeItem.flagged" 
			:author="activeItem.author"
			:item="activeItem.title?.toUpperCase()"/>
		</div>
	</div>
</template>
<script>
import { 
	timeAgo, 
	formatCurrency 
} from "../../utils";
import { collections } from "../../firebaseConfig";
import {
	marketplace_categories, 
	marketplace_filter,
	destinations,
	payment_methods,
} from "../../data"
import ItemActionBar from "../common/ItemActionBar.vue";
import AvatarPosts from '../common/AvatarPosts.vue';
import SendMessageBox from '../common/SendMessageBox.vue';
export default {
	name: "marketplace-details",
	props: {
		activeItem: {
			type: Object,
			required: true,
		}
	},
	components: {
		ItemActionBar, 
		AvatarPosts,	
		SendMessageBox,	
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile
		},
		imgPlaceholder(){
			return this.$store.state.imgPlaceholder;
		},
	},
	data(){
		return{
			isBusy: false,
			timeAgo,
			formatCurrency,
			collections,
			payment_methods,
			destinations,
			marketplace_filter,
			marketplace_categories,
		}
	},
	methods:{
		
	},
	mounted(){
		this.$store.commit("setPageTitle", "Marketplace");
	}

}
</script>