<template>
	<div class="row mb-5 pt-1">	
		<div class="h3 my-3">Marketplace</div>
		<div class="col-12 d-grid">
			<button v-if="isLoggedIn" class="btn btn-dark my-2" @click="$router.push('/messages')">
				Messages ({{ msgCount.length }})
			</button>
			<button class="btn btn-dark my-2" @click="$router.push('/marketplace/add')">
				Create new listing
			</button>
			<button class="btn btn-outline-dark my-2 mb-4" @click="$router.push('/marketplace/list')">
				View my listings
			</button>
			<div class="h5 my-3">Filter</div>
			<div class="form-check ms-3" v-for="(filter, index) in marketplace_filter" :key="index">
				<input class="form-check-input" type="checkbox" :value="filter.id" :id="`filter${index}`">
				<label class="form-check-label" :for="`filter${index}`">
					{{ filter.name }}
				</label>
			</div>

			<div class="h5 my-3">Categories</div>
			<div class="form-check ms-3" v-for="(category, index) in marketplace_categories" :key="index">
				<input class="form-check-input" type="checkbox" :value="category.id" :id="`category${index}`">
				<label class="form-check-label" :for="`category${index}`">
					{{ category.name }}
				</label>
			</div>
		</div>
	</div>
</template>

<script>
import {
	marketplace_categories, 
	marketplace_filter
} from '../../data';
export default {
	name: "MarketplaceFilter",
	components: {

},
	computed:{
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		msgCount(){
			const messages = this.$store.state.messages;
			const profileId = this.userProfile.id;
			if(Array.isArray(messages) && profileId){
				return messages.filter((msg)=> !msg.read.includes(profileId));
			}else{
				return [];
			}
		},
	},
	data(){
		return{
			filter:[],
			marketplace_categories,
			marketplace_filter,
		}
	},
	methods:{
		badgeColor(post_type){
			switch(post_type){
				case "selling":
					return "bg-info"
					break
				case "buying":
					return "bg-warning"
					break
				default:
					return "bg-dark"
			}
			return 
		},
		btnFilterClick(){
			this.filter = {
				category: "",
				country: "",
				post_type: "",
			};
		},
		onFilterChange(){
			this.showBtnClearFilter = true;
		},
		itemClick(selectedItem, selectedId){
			this.activeItem = selectedItem;
			this.activeItemId = selectedId;
			this.activeItemAuthor = selectedItem.author;
			this.marketplaceComponentKey = Date.now();
		},
		alertClick(){
			this.$store.dispatch("loadMarketplacePosts");
			window.scrollTo(0,0);
		},
		likeButtonClick(){

		},
	},
	mounted(){
		this.$store.commit("setPageTitle", "Marketplace");
	}

}
</script>
