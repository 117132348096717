<template>
	<div class="row mb-5">
		<FloatingActionButton icon="bi-plus" class="d-lg-none"  @click="$router.push('/marketplace/add')" />
		<div class="col-12 col-md-4 col-lg-3 order-md-last border-start d-none d-lg-block p-3" style="height: 85vh;">
			<div class="d-grid my-3">
				<button v-if="isLoggedIn" class="btn btn-dark my-2" @click="$router.push('/messages')">
					Messages ({{ msgCount.length }})
				</button>
				<button class="btn btn-dark my-2" @click="$router.push('/marketplace/add')">
					Create new listing
				</button>
				<button class="btn btn-outline-dark my-2" @click="$router.push('/marketplace')">
					Browse all items
				</button>
			</div>
		</div>
		<div class="col-12 col-lg-9 mb-5">
			<div class="h5 mt-lg-3"><span class="pointer" @click="$router.go(-1)"><i class="bi bi-chevron-left pointer h5 me-4"></i> </span> Listings ({{myListings.length}})</div>
			<p class="my-5 text-muted" align="center" v-if="myListings.length<1">
				<em><i class="bi bi-shop-window"></i> Your items will appear here...</em>
			</p>
			<div class="row">
				<div class="col-12 col-md-6 col-lg-4 my-3" v-for="item in myListings" :key="item.id">
					<MarketplaceContent :item="item" />
					<div align="right" class="d-grid">
						<button class="btn btn-sm btn-dark my-2" data-bs-toggle="modal" data-bs-target="#confirmDeleteMarketplace" @click="docToDelete = item.id">
							Delete this item
						</button>
					</div>
					<div class="col-12 d-md-none border-bottom"> &nbsp; </div>
				</div>
			</div>
		</div>

		<!-- Modal -->
		<div class="modal fade" id="confirmDeleteMarketplace" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="confirmDeleteMarketplaceLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="confirmDeleteMarketplaceLabel">Confirm delete</h5>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body">
					Are you sure you want to delete this post?
				</div>
				<div class="modal-footer">
					<button id="btnCancelDelete" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					<button type="button" class="btn btn-dark" @click="confirmDeleteClick">
						<span v-show="!isBusy">Continue</span>
						<div class="spinner-border text-light" role="status" v-show="isBusy">
							<span class="sr-only">Loading...</span>
						</div>
					</button>
				</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { useToast } from "vue-toastification";
import { doc, deleteDoc } from "firebase/firestore";
import { db, collections } from "../firebaseConfig";
import { formatDate } from "../utils";
import MarketplaceContent from "../components/marketplace/MarketplaceContent.vue";
import FloatingActionButton from "../components/common/FloatingActionButton.vue";

const toast = useToast();

export default {
	name: 'MarketplaceList',
	components: {
		FloatingActionButton,
		MarketplaceContent,
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile
		},
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		},
		myListings(){
			const marketplace =  this.$store.state.marketplace;
			if(Array.isArray(marketplace)){
				return (marketplace.filter((item) => item.author.id == this.userProfile.id));
			}else{
				return [];
			}
		},
		msgCount(){
			const messages = this.$store.state.messages;
			if(Array.isArray(messages)){
				return messages.filter((msg) =>  !msg.read.includes(this.userProfile.id));
			}else{
				return [];
			}
		},
	},
	data(){
		return{
			isBusy: false,
			docToDelete: null,
			formatDate,
		}
	},
	methods:{
		async confirmDeleteClick(){
			this.isBusy = true;
			try{
				await deleteDoc(doc(db, collections.MARKETPLACE, this.docToDelete));
				toast.success("Item deleted.", {
					timeout: 3000
				});
			}catch(error){
				console.log(error);
				toast.error("Error deleting item.", {
					timeout: 3000
				});
			}finally{
				this.isBusy = false;
				document.getElementById("btnCancelDelete").click();
			}
		},
	},
	mounted(){
		this.$store.commit("setPageTitle", "My Listings")
	}

}
</script>
