<template>
	<div>
		<div class="row px-2">
			<div class="col-12 col-lg-3">
				
			</div>
            <div class="col-12 col-lg-6">
                <div class="row">
					<div class="col-12 mb-3 mt-4 h5 pointer" @click="$router.push('/marketplace')">
						<i class="bi bi-chevron-left"></i> Back
					</div>
					<p class="h4 my-3 fw-bold">New marketplace item</p>
					<div class="col-12 my-2">
						<label class="small text-secondary">What are you doing in this post?</label>
						<div class="form-check" v-for="(filter, index) in marketplace_filter" :key="index">
							<input class="form-check-input" type="radio" :value="filter.id" v-model="item.post_type" name="marketplaceFilter" :id="`marketplaceFilter${index}`">
							<label class="form-check-label" :for="`marketplaceFilter${index}`">
								{{filter.name}}
							</label>
						</div>
					</div>
					<div class="col-12 my-2">
						<label class="small text-secondary">Item category*</label>
						<select class="form-select" v-model="item.category">
							<option disabled selected value=""></option>
							<option v-for="category in marketplace_categories" :key="category" :value="category.id">{{category.name}}</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="small text-secondary">Item name*</label>
						<input class="form-control" v-model="item.title"/>
					</div>
					<div class="col-12 my-2">
						<label class="small text-secondary">Available country*</label>
						<select class="form-select" v-model="item.country">
							<option disabled selected value=""></option>
							<option v-for="destination in destinations" :key="destination.id" :value="destination.id">{{destination.name}}</option>
						</select>
					</div>
					<div class="col-12 my-2">
						<label class="small text-secondary">Describe your item*</label>
						<textarea rows="7" class="form-control" wrap="hard" placeholder="Provide a clear description for your item."  v-model="item.details"/>
					</div>
					<div class="col-12 my-2" v-show="item.post_type==='selling'">
						<label class="small text-secondary">Asking price (USD)*</label>
						<input type="number" step="0.01" class="form-control" v-model="item.price"/>
					</div>
					<div class="col-12 my-2">
						<p class="h5">Payment method(s) accepted*</p> 
						<div class="form-check mb-2" v-for="(payment_method, index) in payment_methods" :key="index">
							<input class="form-check-input" type="checkbox" :value="payment_method.id" :id="`paymentMethod${index}`" v-model="item.payment_methods">
							<label class="form-check-label" :for="`paymentMethod${index}`">
								{{ payment_method.name }}
							</label>
						</div>
					</div>
					<div class="col-12 my-2">
						<p class="h5">Image*</p> 
						<ImageUploader  @setDownloadUrl="setDownloadUrl" />
					</div>
					<div class="col-12 my-4 mb-5">
						<p class="h5">Legal*</p> 
						<div class="form-check mb-2">
							<input class="form-check-input" type="checkbox" value="true" id="dataConsent" v-model="item.data_consent">
							<label class="form-check-label" for="dataConsent">
								I consent to Soca Islands processing my data.
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" value="true" id="dataTerms" v-model="item.agree_terms">
							<label class="form-check-label" for="dataTerms">
								I agree the <a  class="text-dark" href="https://socaislands.com/legal-stuff" target="_terms">terms and conditions of Soca Islands' Marketplace.</a>
							</label>
						</div>
					</div>
					<div  class="col-12 p-2 d-grid fixed-bottom1 bg-white1 border-top1" >
						<button class="btn btn-block btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnSubmitClick">
							<span v-if="!isBusy">Create new item</span>
							<div class="spinner-border text-light" role="status" v-else>
								<span class="sr-only">Loading...</span>
							</div>
						</button>
					</div>
				</div>
            </div>
            <div class="col-12 col-lg-2">
            </div>
		</div>

	</div>
</template>

<script>
import { 
	collection, 
	addDoc 
} from "firebase/firestore"; 
import { 
	collections, 
	db 
} from "../firebaseConfig";
import {
	destinations,
	marketplace_filter, 
	marketplace_categories, 
	payment_methods
} from "../data";
import ImageUploader from '@/components/common/ImageUploader'
export default {
	name: "MarketplaceAdd",
	components: {
		ImageUploader,
	},
	computed:{
		userProfile(){
			return this.$store.state.userProfile
		},
		isFormValid(){
			const { item } = this;
			if(!item.post_type) return false
			if(item.post_type=="selling" && item.price<1) return false
			if(!item.category) return false
			if(!item.country) return false
			if(!item.title) return false
			if(!item.details) return false
			if(item.payment_methods.length<1) return false
			if(!item.data_consent) return false
			if(!item.agree_terms) return false 
			if(Object.keys(item.image).length < 1) return false;
			return true
		},
	},
	data(){
		return{
			destinations,
			isBusy: false,
			marketplace_filter,
			marketplace_categories,
			payment_methods,
			imgData: { imgFile: null, imgFileName: null },
			item:{
				status: true,
				flagged: false,
				in_review: false,
				likes:[],
				saved_by: [],
				author: {},
				post_type: "",
				category: "",
				country: "",
				title: "",
				price: "",
				details: "",
				post_image: "",
				payment_methods:[],
				data_consent: false,
				agree_terms: false,
				image: {},
			},
		}
	},
	methods:{
		async btnSubmitClick(){
			const { item } = this;
			const {id, created_at, fname, lname} = this.userProfile;
			const timeStamp = Date.now();
			this.isBusy = true
			item.author = {id, name: `${fname} ${lname}` , created_at }
			item.price = (item.post_type === "selling") ? item.price : 0 
			item.created_at = timeStamp;
			item.updated_at = timeStamp;

			try{
				await addDoc(collection(db, collections.MARKETPLACE), item);
				this.$router.push("/marketplace/list");
			}catch(error){
				console.log(error);
			}finally{
				this.isBusy = false;
			}
		},
		setDownloadUrl(data){
			this.item.image = data;
		},
	},

	async mounted(){
		this.$store.commit("setPageTitle", "new-listing")
	}

}
</script>
