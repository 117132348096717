<template>
	<div class="row mb-5">
		<FloatingActionButton class="d-lg-none " data-bs-toggle="offcanvas" data-bs-target="#marketplaceFilters"
			aria-controls="marketplaceFilter" />
		<div v-if="stagingMarketplace.length > 0" class="marketplace-newitem-alert alert alert-primary pointer"
			align="center" @click="alertClick">
			<span v-if="stagingMarketplace.length == 1">View 1 new item.</span>
			<span v-if="stagingMarketplace.length > 1">View {{ stagingMarketplace.length }} new items.</span>
		</div>
		<div class="col-12 col-lg-3 order-md-last d-none d-lg-block" style="position: releative;">
			<div class="hide-scrollbar border-start border-light p-lg-3"
				style="position: fixed; height: 90vh; width:23vw; overflow-y: auto;">
				<MarketplaceFilter />
			</div>
		</div>
		<div class="col-12 col-lg-9 mb-5 py-md-4">
			<div class="h1 mb-4 d-md-none">
				Marketplace
			</div>
			<!-- <PageCallOut :title="`COVID-19: Buyers and Sellers`"
				:body="`Please follow local guidelines about physical distancing and staying home.`" /> -->
			<PostSkeleton v-if="!Array.isArray(posts)" />
			<div class="my-5 text-muted" align="center" v-else-if="Array.isArray(posts) && posts.length < 1">
				<em><i class="bi bi-shop"></i> Marketplace items will appear here...</em>
			</div>
			<div class="row" v-else>
				<template v-for="item in posts" :key="item.id">
					<div class="col-12 col-md-6 col-lg-4 col-xl-4" data-bs-toggle="modal"
						data-bs-target="#viewMarketplaceItem" @click="activeItem = item">
						<MarketplaceContent :item="item" />
					</div>
				</template>
			</div>

			<!-- Modal -->
			<div class="modal fade" id="viewMarketplaceItem" data-bs-backdrop="static" data-bs-keyboard="false"
				tabindex="-1" aria-labelledby="viewMarketplaceItemLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-scrollable modal-xl">
					<div class="modal-content">
						<div class="modal-header pb-0">
							<h5 class="modal-title" id="viewMarketplaceItemLabel"> </h5>
							<button type="button" class="btn-close m-1" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<MarketplaceDetails :activeItem="activeItem" :key="activeItem.id" />
						</div>
					</div>
				</div>
			</div>

			<!-- Off Canvas -->
			<div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="marketplaceFilters"
				aria-labelledby="marketplaceFilterLabel">
				<div class="offcanvas-header">
					<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
						aria-label="Close"></button>
				</div>
				<div class="offcanvas-body">
					<MarketplaceFilter />
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import MarketplaceContent from "../components/marketplace/MarketplaceContent.vue";
import MarketplaceDetails from "../components/marketplace/MarketplaceDetails.vue";
import FloatingActionButton from "../components/common/FloatingActionButton.vue";
import MarketplaceFilter from '../components/marketplace/MarketplaceFilter.vue';
import PageCallOut from '../components/common/PageCallOut.vue';
import PostSkeleton from '../components/common/PostSkeleton.vue';

export default {
	name: "Marketplace",
	components: {
		MarketplaceContent,
		MarketplaceDetails,
		FloatingActionButton,
		MarketplaceFilter,
		PageCallOut,
		PostSkeleton,
	},
	computed: {
		userProfile() {
			return this.$store.state.userProfile
		},
		isLoggedIn() {
			return this.$store.state.isLoggedIn;
		},
		posts() {
			const marketplace = this.$store.state.marketplace;
			if (Array.isArray(marketplace)) {
				if (this.filter.post_type == "" && this.filter.category == "" && this.filter.country == "") return marketplace;
				const filter = (item) => {
					return (item.post_type == this.filter.post_type &&
						item.category == this.filter.category &&
						item.country == this.filter.country);
				}
				return marketplace.filter(filter);
			} else {
				return marketplace;
			}
		},
		stagingMarketplace() {
			return this.$store.state.stagingMarketplace;
		},
		imgPlaceholder() {
			return this.$store.state.imgPlaceholder;
		},
	},
	data() {
		return {
			isBusy: false,
			isLoaded: false,
			filter: {
				category: "",
				country: "",
				post_type: "",
			},
			activeItem: {},
		}
	},
	methods: {
		btnFilterClick() {
			this.filter = {
				category: "",
				country: "",
				post_type: "",
			};
		},
		alertClick() {
			this.$store.dispatch("loadMarketplacePosts");
			window.scrollTo(0, 0);
		},
		likeButtonClick() {

		},
	},
	mounted() {
		this.$store.commit("setPageTitle", "Marketplace");
	}

}
</script>
<style scoped>
.item-caption {
	font-size: .7rem;
	text-transform: uppercase;
}
</style>